import React, { useEffect, useRef, useState } from 'react'
import img_person from '../../../assets/imgs/home/bg_03.png'
import img_icon from '../../../assets/imgs/home/bg_01.png'
import img_icon_hover from '../../../assets/imgs/home/bg_01_hover.png'
import img_logo from '../../../assets/imgs/icon/logo.png'
import img_bg from '../../../assets/imgs/home/bg_02.png'
import icon_twitter from '../../../assets/imgs/icon/ic-twitter.png'
import icon_telegram from '../../../assets/imgs/icon/ic-telegram.png'
import icon_discord from '../../../assets/imgs/icon/ic-discord.png'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../../contexts/LanguageContext'

// @ts-ignore
import Parallax from 'parallax-js'
import medium from "../../../assets/imgs/icon/ic-medium.png";

interface IHeader {
    togglePopup: () => void
}

function Header({ togglePopup }: IHeader) {
    const [showLanguage, setShowLanguage] = useState(false)
    const [isChangeBg, setIsChangeBg] = useState(false)
    const langRef = useRef<any>()
    const { t } = useTranslation()
    const { changeLanguage } = useLanguage()

    useEffect(() => {
        if (showLanguage) {
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }
    }, [langRef, showLanguage])

    const handleMouseEnter = () => {
        setIsChangeBg(true)
    }
    const handleMouseLeave = () => {
        setIsChangeBg(false)
    }

    useEffect(() => {
        const parallaxScenes = document.getElementById('parallax-scenes')
        const parallaxInstance = new Parallax(parallaxScenes, {
            pointerEvents: true,
            hoverOnly: true,
        })

        return () => {
            parallaxInstance.destroy()
        }
    }, [])

    const handleClickOutside = (event: any) => {
        if (langRef.current && !langRef.current.contains(event.target)) {
            setShowLanguage(false)
        }
    }

    const handleShowLang = () => {
        setShowLanguage(!showLanguage)
    }

    const handleChangeLang = (value: string) => {
        changeLanguage && changeLanguage(value)
        handleShowLang()
    }

    return (
        <>
            <header className='header'>
                <div className='container'>
                    <div className='header__top'>
                        <Link to={'/'} className='header__logo'>
                            <img src={img_logo} alt='logo'/>
                        </Link>
                        <div className='header__right'>
                            <Link to={'/'} className='header__btn'>
                                {t('home.pitchButton')}
                            </Link>
                            <ul className='header__nav'>
                                <li>
                                    <a href='https://twitter.com/YYY57304814' target={"_blank"}>
                                        <img src={icon_twitter} alt='twitter'/>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://medium.com/@yyyofficial' target={"_blank"}>
                                        <img src={medium} alt='Medium'/>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://discord.gg/cwjZKUKcSf' target={"_blank"}>
                                        <img src={icon_discord} alt='discord'/>
                                    </a>
                                </li>
                                {/*<li ref={langRef}>
                                    <p onClick={handleShowLang}>
                                        <img src={icon_lang} alt='language'/>
                                    </p>
                                    <ul
                                        className='header__dropdown'
                                        style={{ display: !!showLanguage ? 'block' : 'none' }}
                                    >
                                        <li>
                                            <p onClick={() => handleChangeLang('en')}>
                                                <img src={img_us} alt='usa'/> {t('home.lang.en')}
                                            </p>
                                        </li>
                                        <li>
                                            <p onClick={() => handleChangeLang('vi')}>
                                                <img src={img_vn} alt='vietnam'/> {t('home.lang.vi')}
                                            </p>
                                        </li>
                                        <li>
                                            <p onClick={() => handleChangeLang('zh')}>
                                                <img src={img_cn} alt='china'/> {t('home.lang.zh')}
                                            </p>
                                        </li>
                                    </ul>
                                </li>*/}
                            </ul>
                        </div>
                    </div>
                    <ul className='header__list'>
                        <li>
                            <Link duration={500} smooth={true} to='sec01'>
                                {t('home.nav.text1')}
                            </Link>
                        </li>
                        <li>
                            <Link duration={500} offset={-50} smooth={true} to='sec02'>
                                {t('home.nav.text2')}
                            </Link>
                        </li>
                        <li>
                            <Link duration={500} offset={-50} smooth={true} to='sec03'>
                                {t('home.nav.text3')}
                            </Link>
                        </li>
                        <li>
                            <Link duration={500} offset={-50} smooth={true} to='sec04'>
                                {t('home.nav.text4')}
                            </Link>
                        </li>
                        <li>
                            <Link duration={500} offset={-50} smooth={true} to='sec05'>
                                {t('home.nav.text5')}
                            </Link>
                        </li>
                        <li>
                            <Link duration={500} offset={-50} smooth={true} to='sec06'>
                                {t('home.nav.text6')}
                            </Link>
                        </li>
                    </ul>
                    <div className='header__gr'>
                        <h2 className='header__tt'>{t('home.banner.title')}</h2>
                        <p className='header__text'>{t('home.banner.description')}</p>
                    </div>
                    <Link
                        to={'/'}
                        className='header__btn01'
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={togglePopup}
                    >
                        {t('home.signUp.button')}
                    </Link>
                </div>
                <div className='header__bg'>
                    <div id={'parallax-scenes'}>
                        <div data-depth='0.24'>
                            <img
                                className='header__bg__img01'
                                src={img_person}
                                alt='image01'
                            />
                        </div>
                        <div data-depth='0.2'>
                            <img
                                className='header__bg__img02'
                                src={isChangeBg ? img_icon_hover : img_icon}
                                alt='image02'
                            />
                        </div>
                        <div data-depth='0.12'>
                            <img className='header__bg__bg01' src={img_bg} alt='img_bg'/>
                        </div>
                    </div>
                    <div className='header__bg__bottom'></div>
                </div>
            </header>
        </>
    )
}

export default Header
