import React from 'react'

interface ITitle {
    title: string
    subTitle?: string
}

function Title({ title, subTitle }: ITitle) {
    return (
        <div className='title-main'>
            {title} <span>{subTitle}</span>
        </div>
    )
}

export default Title
