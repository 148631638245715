import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Title from './title'
import dot from '../../../assets/imgs/home/roadmap_dot.svg'
import past from '../../../assets/imgs/home/icon_past.svg'
import current from '../../../assets/imgs/home/current.svg'
// Import Swiper React components
// @ts-ignore
import { Swiper } from 'swiper/react/swiper.js'
// @ts-ignore
import { SwiperSlide } from 'swiper/react/swiper-slide.js'

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
// import required modules
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'

function Roadmap() {
    const date = new Date()
    const currentMonth = date.getMonth() + 1
    const currentYears = date.getFullYear()
    const currentMonthYears = `${
        currentMonth > 9 ? currentMonth : '0' + currentMonth
    }/${currentYears}`

    const { t } = useTranslation()
    let roadMapContent = [
        {
            id: 0,
            position: 'down',
            time: '',
            title: '',
            list: [],
            currentShow: ['05/2022', '06/2022'],
        },
        {
            id: 3,
            position: 'down',
            time: t('home.roadMap.content3.time'),
            title: t('home.roadMap.content3.title'),
            list: [
                t('home.roadMap.content3.list.item01'),
                t('home.roadMap.content3.list.item02'),
                t('home.roadMap.content3.list.item03'),
            ],
            currentShow: ['04/2022', '06/2022'],
        },
        {
            id: 4,
            position: 'up',
            time: t('home.roadMap.content4.time'),
            title: t('home.roadMap.content4.title'),
            list: [
                t('home.roadMap.content4.list.item01'),
                t('home.roadMap.content4.list.item02'),
                t('home.roadMap.content4.list.item03'),
            ],
            currentShow: ['06/2022', '09/2022'],
        },
        {
            id: 5,
            position: 'down',
            time: t('home.roadMap.content5.time'),
            title: t('home.roadMap.content5.title'),
            list: [
                t('home.roadMap.content5.list.item01'),
                t('home.roadMap.content5.list.item02'),
            ],
            currentShow: ['09/2022', '12/2022'],
        },
        {
            id: 0,
            position: 'down',
            time: '',
            title: '',
            list: [],
            currentShow: ['05/2022', '06/2022'],
        },
        {
            id: 0,
            position: 'down',
            time: '',
            title: '',
            list: [],
            currentShow: ['05/2022', '06/2022'],
        }
    ]
    const [currentRoadMapContent, setCurrentRoadMapContent] = useState<number>()
    const [disabledHoverBlock, setDisabledHoverBlock] = useState(false)

    const dotToShow = (currentShow: string[]) => {
        if (currentShow.includes(currentMonthYears)) {
            return current
        } else if (
            !!currentShow.length &&
            +currentShow[0].slice(0, 2) < currentMonth
        ) {
            return past
        }
        return dot
    }

    const handlePosition = (currentShow: string[]) => {
        if (currentShow.includes(currentMonthYears)) {
            return 'current active'
        } else if (
            !!currentShow.length &&
            +currentShow[0].slice(0, 2) < currentMonth
        ) {
            return 'past'
        }
        return ''
    }

    const handleMouseEnter = (e: any, id: any) => {
        setCurrentRoadMapContent(id)
    }
    const handleMouseLeave = (e: any, id: any) => {
        if (
            e.target.className == 'roadmap__item__title' ||
            e.relatedTarget.className == 'roadmap__item__block'
        ) {
            setCurrentRoadMapContent(id)
            setDisabledHoverBlock(true)
        } else {
            setCurrentRoadMapContent(0)
            setDisabledHoverBlock(false)
        }
    }

    let slidesPerView = 5
    let cssClass = ''
    if (window.matchMedia('(max-width: 700px)').matches) {
        slidesPerView = 1
        cssClass = 'active'

        roadMapContent = roadMapContent.filter(x => x.id > 0)
    }

    return (
        <>
            <div className='roadmap'>
                <Title title={t('home.roadMap.title')}/>
                <div className='roadmap__content'>
                    <Swiper
                        cssMode={true}
                        spaceBetween={30}
                        navigation={true}
                        pagination={true}
                        mousewheel={true}
                        keyboard={true}
                        slidesPerView={slidesPerView}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        className='mySwiper'
                    >
                        {roadMapContent.map((value, index) => (
                            <SwiperSlide key={index}>
                                <div
                                    className={`roadmap__item ${value.position} ${handlePosition(
                                        value.currentShow
                                    )} active ${cssClass} ${value.id === 0 ? 'hide' : ''}`}
                                >
                                    <div className='roadmap__item__block'>
                                        <div
                                            className='wrap'
                                            data-id={value.id}
                                            onMouseEnter={e => handleMouseEnter(e, value.id)}
                                            onMouseLeave={e => handleMouseLeave(e, value.id)}
                                        >
                                            <h2 className='roadmap__item__time'>{value.time}</h2>
                                        </div>
                                        {value && !!value.list.length && (
                                            <ul className='roadmap__item__list'>
                                                {value.list.map((item: any, index2: any) => (
                                                    <li key={index2}>{item}</li>
                                                ))}
                                            </ul>
                                        )}
                                        <span className='roadmap__item__arrow'></span>
                                    </div>
                                    <span className='roadmap__item__dot'>
                    <img
                        src={dotToShow(value.currentShow)}
                        alt='icon dot'
                        onMouseEnter={e => handleMouseEnter(e, value.id)}
                        onMouseLeave={e => handleMouseLeave(e, value.id)}
                    />
                  </span>
                                    <span className='roadmap__item__line'></span>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default Roadmap
