import React from 'react'
import { useTranslation } from 'react-i18next'
import titwer from '../../../assets/imgs/icon/f_titwer.svg'
import telegram from '../../../assets/imgs/icon/f_telegram.svg'
import discord from '../../../assets/imgs/icon/f_discord.svg'
import medium from '../../../assets/imgs/icon/ic-medium.png'

function Footer() {
    const { t } = useTranslation()
    return (
        <footer className='footer'>
            <p className='footer__text'>{t('home.footer')}</p>
            <ul className='footer__nav'>
                <li>
                    <a href='https://twitter.com/YYY57304814' target={"_blank"}>
                        <img src={titwer} alt='Twitter'/>
                    </a>
                </li>
                <li>
                    <a href='https://medium.com/@yyyofficial' target={"_blank"}>
                        <img src={medium} alt='Medium'/>
                    </a>
                </li>
                <li>
                    <a href='https://discord.gg/cwjZKUKcSf' target={"_blank"}>
                        <img src={discord} alt='Discord'/>
                    </a>
                </li>
            </ul>
        </footer>
    )
}

export default Footer
