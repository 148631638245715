import React, { useState } from 'react'
import Ecosystem from './components/ecosystem'
import Footer from './components/footer'
import Header from './components/header'
import Investors from './components/investors'
import Partners from './components/partners'
import Popup from './components/popup'
import Roadmap from './components/roadmap'
import Summary from './components/summary'
import Team from './components/team'
import './Home.scss'
import { Element } from 'react-scroll'

function Home() {
  const [showPopup, setShowPopup] = useState(false)

  const togglePopup = () => {
    setShowPopup(!showPopup)
  }

  return (
    <>
      <div className='homepage'>
        <Header togglePopup={togglePopup} />
        <main className='homepage_content'>
          <Element name='sec01'>
            <Summary />
          </Element>
          <Element name='sec02'>
            <Ecosystem />
          </Element>
          <Element name='sec03'>
            <Roadmap />
          </Element>
          <Element name='sec04'>
            <Team />
          </Element>
          <Element name='sec06'>
            <Partners />
          </Element>
        </main>
        <Footer />
      </div>
      <Popup togglePopup={togglePopup} showPopUp={showPopup} />
    </>
  )
}

export default Home
