import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import img from '../../../assets/imgs/home/modal_img.png'
import close from '../../../assets/imgs/icon/icon_close.svg'

interface IPopup {
    showPopUp: boolean
    togglePopup: () => void
}

function Popup({ showPopUp, togglePopup }: IPopup) {
    const { t } = useTranslation()

    const [isExecuting, setIsExecuting] = useState(false)
    const [name, setName] = useState('')
    const nameInputRef = useRef<HTMLInputElement>(null);

    const [email, setEmail] = useState('')
    const emailInputRef = useRef<HTMLInputElement>(null)

    const [emailError, setEmailError] = useState('')

    const onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value

        setName(value)
    }

    const onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value

        setEmail(value)

        if (!isValidEmail(email)) {
            setEmailError(t('error.invalidEmail'))
        } else {
            setEmailError('')
        }
    }

    function isValidEmail(email: string) {
        return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    }

    const onSubmit = async () => {
        setEmailError('')

        if (!name) {
            // @ts-ignore
            nameInputRef.current.focus()

            return
        }

        if (!email) {
            // @ts-ignore
            emailInputRef.current.focus()

            return
        }

        if (!isValidEmail(email)) {
            setEmailError(t('error.invalidEmail'))

            return
        }

        setIsExecuting(true)


        const formData = new FormData()
        formData.append('MERGE0', email)
        formData.append('MERGE1', name)

        await fetch("https://ventorylabs.us14.list-manage.com/subscribe/post?u=85ca79af7fb14b99666938b55&id=5b6a222f55", {
            method: 'post',
            body: formData,
            mode: 'no-cors',
            credentials: "include"
        })

        setIsExecuting(false)
        setName('')
        setEmail('')
    }

    return (
        <>
            <div className={showPopUp ? 'modal fadeIn' : 'modal'}>
                <div className='modal__bg' onClick={togglePopup}></div>
                <div className='modal__content'>
                    <div className='modal__box'>
                        <div className='modal__img'>
                            <img src={img} alt='modal image'/>
                        </div>
                        <div className='modal__right'>
                            <h2 className='modal__right__text_head'>
                                {t('home.modal.text_head')}
                            </h2>
                            <ul className='modal__right__list'>
                                <li>{t('home.modal.list.item01')}</li>
                                <li>{t('home.modal.list.item02')}</li>
                                <li>{t('home.modal.list.item03')}</li>
                                <li>{t('home.modal.list.item04')}</li>
                            </ul>
                            <input ref={nameInputRef}
                                   value={name}
                                   onChange={onNameChange}
                                   className='modal__input'
                                   placeholder={t('home.modal.name')}
                                   type='text'
                            />
                            <input ref={emailInputRef}
                                   value={email}
                                   onChange={onEmailChange}
                                   className='modal__input'
                                   placeholder={t('home.modal.email')}
                                   type='mail'
                            />
                            <div className={"error"}>
                                {emailError}
                            </div>
                            <button type='submit' className='modal__submit' onClick={onSubmit} disabled={isExecuting}>
                                {t(isExecuting ? 'home.modal.button_sending' : 'home.modal.button')}
                            </button>
                        </div>
                    </div>
                    <p className='modal__close' onClick={togglePopup}>
                        <img src={close} alt='icon close'/>
                    </p>
                </div>
            </div>
        </>
    )
}

export default Popup
