import React from 'react'
import Title from './title'
import { useTranslation } from 'react-i18next'
import img_01 from '../../../assets/imgs/home/img_01.png'
import nav_img_01 from '../../../assets/imgs/home/nav_img_01.png'
import nav_img_02 from '../../../assets/imgs/home/nav_img_02.png'

function Summary() {
    const { t } = useTranslation()

    return (
        <>
            <div className='summary'>
                <div className='summary__container'>
                    <Title
                        title={t('home.summary.title')}
                        subTitle={t('home.summary.subTitle')}
                    />
                    <ul className='summary__nav'>
                        <li>
                            <div className='summary__nav__text'>
                                <h2 className='title_01'>{t('home.summary.box1.title')}</h2>
                                <ul className='list_01'>
                                    <li dangerouslySetInnerHTML={{ __html: t('home.summary.box1.text01') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('home.summary.box1.text02') }}></li>
                                    <li dangerouslySetInnerHTML={{ __html: t('home.summary.box1.text03') }}></li>
                                </ul>
                            </div>
                            <div className='summary__nav__img'>
                                <img src={img_01} alt='box image 01'/>
                            </div>
                        </li>
                        <li>
                            <div className='summary__nav__text'>
                                <h2 className='title_01'>{t('home.summary.box2.title')}</h2>
                                <ul className='list_01'>
                                    <li>{t('home.summary.box2.text01')}</li>
                                    <li>{t('home.summary.box2.text02')}</li>
                                    <li>{t('home.summary.box2.text03')}</li>
                                    <li>{t('home.summary.box2.text04')}</li>
                                </ul>
                            </div>
                            <div className='summary__nav__img'>
                                <img src={nav_img_01} alt='nav image 01'/>
                            </div>
                        </li>
                        <li>
                            <div className='summary__nav__text'>
                                <h2 className='title_01'>{t('home.summary.box3.title')}</h2>
                                <div className={"split-columns-2"}>
                                    <div>
                                        <p className='title_02'>{t('home.summary.box3.sub1.title')}</p>
                                        <ul className='list_01 mb10'>
                                            <li>{t('home.summary.box3.sub1.text01')}</li>
                                            <li>{t('home.summary.box3.sub1.text02')}</li>
                                            <li>{t('home.summary.box3.sub1.text03')}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <p className='title_02'>{t('home.summary.box3.sub2.title')}</p>
                                        <ul className='list_01'>
                                            <li>{t('home.summary.box3.sub2.text01')}</li>
                                            <li>{t('home.summary.box3.sub2.text02')}</li>
                                            <li>{t('home.summary.box3.sub2.text03')}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <p className='title_02'>{t('home.summary.box3.sub3.title')}</p>
                                        <ul className='list_01'>
                                            <li>{t('home.summary.box3.sub3.text01')}</li>
                                            <li>{t('home.summary.box3.sub3.text02')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='summary__nav__img'>
                                <img src={nav_img_02} alt='nav image 02'/>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Summary
