import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Title from './title'
import jimmy from '../../../assets/imgs/home/jimmy.png'
import huy from '../../../assets/imgs/home/huy.png'
import david from '../../../assets/imgs/home/david.png'
import phi from '../../../assets/imgs/home/phi.png'
import icon_linkin from '../../../assets/imgs/icon/icon_linkin.svg'

function Team() {
    const { t } = useTranslation()
    const [flipCard, SetFlipCard] = useState('')
    const handleFlipCard = (card: string) => {
        SetFlipCard(flipCard === card ? '' : card);
    }

    return (
        <>
            <div className='team'>
                <Title title={t('home.team.title')}/>
                <ul className='team__nav'>
                    <li onClick={() => handleFlipCard('1')} className={flipCard === '1' ? 'active' : ''}>
                        <div className='team__nav__side team__nav__front'>
                            <figure className='team__nav__img'>
                                <img src={jimmy} alt=''/>
                            </figure>
                            <div className='team__nav__content'>
                                <h3>{t('home.team.member1.name')}</h3>
                                <p>{t('home.team.member1.title')}</p>
                            </div>
                        </div>
                        <div className='team__nav__side team__nav__back'>
                            <a href="https://www.linkedin.com/in/jimmy-chang-3a5080237/" target={"_blank"}><img
                                src={icon_linkin}/></a>
                            <ul>
                                <li>{t('home.team.member1.title')}</li>
                                <li>
                                    {t('home.team.member1.text01')}
                                </li>
                                <li>
                                    {t('home.team.member1.text02')}
                                </li>
                            </ul>
                        </div>
                        <span className='team__nav__line'></span>
                    </li>
                    <li onClick={() => handleFlipCard('3')} className={flipCard === '3' ? 'active' : ''}>
                        <div className='team__nav__side team__nav__front'>
                            <figure className='team__nav__img'>
                                <img src={david} alt=''/>
                            </figure>
                            <div className='team__nav__content'>
                                <h3>{t('home.team.member3.name')}</h3>
                                <p>{t('home.team.member3.title')}</p>
                            </div>
                        </div>
                        <div className='team__nav__side team__nav__back'>
                            <a href="https://www.linkedin.com/in/shinchan-wong-428097a6/" target={"_blank"}><img
                                src={icon_linkin}/></a>
                            <ul>
                                <li>
                                    {t('home.team.member3.title')}
                                </li>
                                <li>
                                    {t('home.team.member3.text01')}
                                </li>
                            </ul>
                        </div>
                        <span className='team__nav__line'></span>
                    </li>
                    <li onClick={() => handleFlipCard('4')} className={flipCard === '4' ? 'active' : ''}>
                        <div className='team__nav__side team__nav__front'>
                            <figure className='team__nav__img'>
                                <img src={phi} alt=''/>
                            </figure>
                            <div className='team__nav__content'>
                                <h3>{t('home.team.member4.name')}</h3>
                                <p>{t('home.team.member4.title')}</p>
                            </div>
                        </div>
                        <div className='team__nav__side team__nav__back'>
                            <a href="https://www.linkedin.com/in/phi-hoang-850349a2/" target={"_blank"}><img
                                src={icon_linkin}/></a>
                            <ul>
                                <li>
                                    {t('home.team.member4.title')}
                                </li>
                                <li>
                                    {t('home.team.member4.text01')}
                                </li>
                                <li>
                                    {t('home.team.member4.text02')}
                                </li>
                            </ul>
                        </div>
                        <span className='team__nav__line'></span>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Team
