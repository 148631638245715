import React from 'react'
import { useTranslation } from 'react-i18next'
import Title from './title'
import img_01 from '../../../assets/imgs/home/eco_img_01.png'
import img_02 from '../../../assets/imgs/home/eco_img_02.png'
import img_03 from '../../../assets/imgs/home/eco_img_03.png'
import img_04 from '../../../assets/imgs/home/eco_img_04.png'

// @ts-ignore
import video from '../../../assets/videos/Tokenomics.webm'
// @ts-ignore
import video1 from '../../../assets/videos/Tokenomics.mp4'

function Ecosystem() {
    const { t } = useTranslation()

    return (
        <>
            <div className='ecosystem'>
                <Title
                    title={t('home.ecosystem.title')}
                    subTitle={t('home.ecosystem.subTitle')}
                />
                <ul className='ecosystem__nav'>
                    <li>
                        <figure className='ecosystem__nav__img'>
                            <img src={img_01} alt='ecosystem image 01'/>
                            <span></span>
                        </figure>
                        <p className='ecosystem__nav__text'>
                            {t('home.ecosystem.nav.text01')}
                        </p>
                    </li>
                    <li>
                        <figure className='ecosystem__nav__img'>
                            <img src={img_02} alt='ecosystem image 02'/>
                            <span></span>
                        </figure>
                        <p className='ecosystem__nav__text'>
                            {t('home.ecosystem.nav.text02')}
                        </p>
                    </li>
                    <li>
                        <figure className='ecosystem__nav__img'>
                            <img src={img_03} alt='ecosystem image 03'/>
                            <span></span>
                        </figure>
                        <p className='ecosystem__nav__text'>
                            {t('home.ecosystem.nav.text03')}
                        </p>
                    </li>
                    <li>
                        <figure className='ecosystem__nav__img'>
                            <img src={img_04} alt='ecosystem image 04'/>
                            <span></span>
                        </figure>
                        <p className='ecosystem__nav__text'>
                            {t('home.ecosystem.nav.text04')}
                        </p>
                    </li>
                </ul>
                <div className='title-main mt-100'>
                    Tokenomics – Stage 1
                </div>
                <div className='ecosystem__video'>
                    <video autoPlay loop muted playsInline>
                        <source src={video} type="video/webm"/>
                        <source src={"https://res.cloudinary.com/dt3umrugf/video/upload/v1649501401/Tokenomics_dcxacn.mp4"} type="video/mp4"/>
                    </video>
                </div>
            </div>
        </>
    )
}

export default Ecosystem
