import React from 'react'
import { useTranslation } from 'react-i18next'
import Title from './title'
import img_01 from '../../../assets/imgs/home/partner_img_01.svg'
import img_02 from '../../../assets/imgs/home/partner_img_02.svg'
import img_03 from '../../../assets/imgs/home/partner_img_03.svg'
import img_04 from '../../../assets/imgs/home/partner_img_04.svg'
import img_05 from '../../../assets/imgs/home/partner_img_05.png'
import img_06 from '../../../assets/imgs/home/partner_img_06.svg'
import img_07 from '../../../assets/imgs/home/partner_img_07.png'
import img_08 from '../../../assets/imgs/home/partner_img_08.png'
import img_09 from '../../../assets/imgs/home/partner_img_09.png'
import img_10 from '../../../assets/imgs/home/partner_img_10.svg'
import img_11 from '../../../assets/imgs/home/partner_img_11.png'
import img_12 from '../../../assets/imgs/home/partner_img_12.png'

function Partners() {
    const { t } = useTranslation()
    const listInvestor = [
        {
            img: img_01,
            alt: 'binance smart chain',
            href: 'https://www.binance.com',
        },
        {
            img: img_02,
            alt: 'game starter',
            href: 'https://www.gamestarter.co',
        },
        {
            img: img_03,
            alt: 'ventorylabs',
            href: 'https://ventorylabs.com',
        },
        {
            img: img_04,
            alt: 'metaplan',
            href: 'https://metaplan.cc',
        },
        {
            img: img_05,
            alt: 'ajuna',
            href: 'https://ajuna.io',
        },
        {
            img: img_06,
            alt: 'souni',
            href: 'https://souni.io',
        },
        {
            img: img_07,
            alt: 'tap fantasy',
            href: 'https://www.tapfantasy.io',
        },
        {
            img: img_08,
            alt: 'ocean mollu',
            href: 'https://oceanmollu.com',
        },
        {
            img: img_09,
            alt: 'demole',
            href: 'https://demole.io/',
        },
        {
            img: img_10,
            alt: 'kyberdynes',
            href: 'https://kyberdyne.games',
        },
        {
            img: img_11,
            alt: 'glory planet',
            href: 'http://gloryplanet.com',
        },
        {
            img: img_12,
            alt: 'interstellar battlefields',
            href: 'https://intersbattle.io',
        },
    ]
    return (
        <>
            <div className='partner'>
                <div className='container'>
                    <Title title={t('home.partner.title')}/>
                    <ul className='list_02'>
                        {listInvestor.map((investor, index) => (
                            <li key={index}>
                                <a href={investor.href} target={"_blank"}>
                                    <img src={investor.img} alt={investor.alt}/>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Partners
