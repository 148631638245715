import { createContext, useContext, useState } from 'react'
import i18n from '../i18n'

export type LanguageValueType = {
  changeLanguage?: (value: string) => void
}

export const LanguageContext = createContext({})

export const LanguageProvider = ({ children }: any) => {
  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value)
  }

  const value: LanguageValueType = {
    changeLanguage,
  }

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => useContext<LanguageValueType>(LanguageContext)
